

































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";
import { v4 as uuid } from "uuid";

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      headers: [
        {
          value: "name",
          text: root.$tc("layout.misc.name"),
        },
        {
          value: "email",
          text: root.$tc("layout.misc.email"),
        },
        {
          text: root.$tc("layout.misc.default"),
          value: "defaultAddress",
          align: "right",
        },
        { text: "", value: "actions", align: "right" },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      error: 400,
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc(
            "panel.globalTools.mailAccount.assignAccountError"
          )}`;
        case 500:
          return `${root.$tc("panel.globalTools.mailAccount.error500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get("mail-account", {
          params: {
            sortBy,
            sortDesc,
            page,
            itemsPerPage,
          },
        })
        .then(({ data: { mailAccounts } }) => {
          state.empty = false;
          state.items = mailAccounts;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);
    watch(() => state.options, fetchData, { deep: true });

    const deleteItem = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`mail-account/${item.id}`)
        .then(() => {
          item.deleteDialog = false;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc(
              "panel.event.participant.assignNfc.removeBulkSuccess"
            ),
          });
          fetchData();
        })
        .catch((error) => {
          state.error = error.response.status;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error),
          });
        })
        .finally(() => (state.loading = false));
    };

    return { state, fetchData, deleteItem };
  },
});
